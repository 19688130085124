import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    NG_VALUE_ACCESSOR,
    Validators,
} from '@angular/forms';
import { FormState } from 'src/app/shared/enums/FormState';
import { MultiDropdownFieldComponent } from '../../../form-fields/multi-dropdown-field/multi-dropdown-field.component';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IconType } from 'src/app/shared/enums/IconType';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { IssueAuditAddNewUserStepOneComponent } from '../issue-audit-add-new-user-step-one/issue-audit-add-new-user-step-one.component';
import { IssueAuditAddNewUserStepTwoComponent } from '../issue-audit-add-new-user-step-two/issue-audit-add-new-user-step-two.component';
import { SubSink } from 'subsink';

export interface IssueAuditSelectUserRequestPayloadPart {
    newUser: NewUserType[];
    assesseUserIds: string[];
}

interface UserViewResposneType {
    id: string;
    label: string;
    newDate: string;
    oldDate: string;
    readyCount: number;
    score: number;
    totalCount: number;
}

interface UserResponseType {
    defaultViewId: string;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    root: boolean;
    userType: 'Root' | 'Non Root';
    userViews: UserViewResposneType[];
}

interface NewUserType {
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
    email: string;
    sendingMechanism: {
        sendViaEmail: boolean;
        emailRequest: {
            emailTo: string[];
            subject: string;
            emailBody: string;
        };
    };
}

interface DropDownOptionType {
    id: string;
    newUserInfo: NewUserType | null;
    existingUserInfo: UserResponseType | null;
}

@Component({
    selector: 'app-issue-audit-select-user-dropdown',
    templateUrl: './issue-audit-select-user-dropdown.component.html',
    styleUrls: ['./issue-audit-select-user-dropdown.component.sass'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: MultiDropdownFieldComponent,
            multi: true,
        },
    ],
})
export class IssueAuditSelectUserDropdownComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input('customerId') private customerId: string | null;
    @Input('domainId') private domainId: string | null;
    @Input('assessmentName') private assessmentName: string;
    @Input('widgetUniqueIdentity') private widgetUniqueIdentity: Symbol;
    @Output('formGroup') private formGroupEventEmitter =
        new EventEmitter<FormGroup>();
    @Output('userListingPayloadPart') private userListingPayloadPart =
        new EventEmitter<IssueAuditSelectUserRequestPayloadPart>();

    @ViewChild('ngSelectRef') ngSelect: NgSelectComponent;

    private subSink = new SubSink();
    public readonly FormState = FormState;
    public formState: FormState = FormState.CREATE;
    public fieldName = 'users';
    public get isDropDownAvailable() {
        return !!this.customerId || this.showLoading;
    }
    public readonly Infinity = Infinity;
    public readonly disableDropdown = false;
    public readonly required = true;
    public showLoading = false;
    private formGroup: FormGroup;
    public get formControl() {
        return this.formGroup.get(this.fieldName);
    }
    public optionsList: DropDownOptionType[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private httpService: HttpService,
        private ngZone: NgZone,
        private notificationService: NotificationsService,
        private modalService: ModalService
    ) {
        this.formGroup = this.formBuilder.group({
            [this.fieldName]: [[], Validators.required],
        });
        this.subSink.sink = this.formControl.valueChanges.subscribe(() => {
            const payload: IssueAuditSelectUserRequestPayloadPart = {
                assesseUserIds: [],
                newUser: [],
            };
            const selctedItems = this.optionsList.filter((el) =>
                this.formControl.value.includes(el.id)
            );
            payload.assesseUserIds = selctedItems
                .filter((el) => !!el.existingUserInfo)
                .map((el) => el.id);
            payload.newUser = selctedItems
                .filter((el) => !!el.newUserInfo)
                .map((el) => el.newUserInfo);
            this.userListingPayloadPart.emit(payload);
        });
    }

    ngOnInit(): void {
        this.formGroupEventEmitter.emit(this.formGroup);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['customerId']) {
            this.fetchUsersList(changes['customerId'].currentValue);
        }
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }

    private fetchUsersList(customerId: string | null) {
        if (customerId) {
            this.showLoading = true;
            const args: IHitApi = {
                url: `${ApiUrls.LIST_TENANT_USERS_ENDPOINT}${customerId}`,
                intactUrl: `${ApiUrls.LIST_TENANT_USERS_ENDPOINT}{tenantId}`,
                input: {},
                requestType: RequestType.POST,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN,
                },
                function: (users: UserResponseType[]) => {
                    this.showLoading = false;
                    this.optionsList = users.map((u) => {
                        return {
                            id: u.id,
                            existingUserInfo: u,
                            newUserInfo: null,
                        };
                    });
                    this.formControl.setValue([]);
                },
                errorFunction: (error) => {
                    this.notificationService.showSnackBar(
                        error?.error?.message ||
                            'Unable to load users list. Please contact application support.',
                        true
                    );
                    this.showLoading = false;
                    this.optionsList = [];
                    this.formControl.setValue([]);
                },
            };
            new HitApi(args, this.httpService, this.ngZone).hitApi();
        } else {
            this.optionsList = [];
            this.formControl.setValue([]);
        }
    }

    public selectionChange(
        event: Event,
        item: DropDownOptionType,
        isAddition: boolean
    ) {
        event.preventDefault();
        event.stopPropagation();
        if (item.newUserInfo) {
            return;
        }
        let newValue = this.formControl.value || [];
        if (isAddition) {
            newValue.push(item.id);
        } else {
            newValue = newValue.filter((id) => id !== item?.id);
        }
        newValue = Array.from(new Set(newValue));
        this.formControl.patchValue(newValue);
    }

    public onSelectAll(event: Event, selectAll: boolean) {
        event.stopPropagation();
        const newSelectedItems = selectAll
            ? this.optionsList.map((el) => el.id)
            : this.optionsList
                  .filter((el) => !!el.newUserInfo)
                  .map((el) => el.id);
        this.formControl.patchValue(newSelectedItems);
    }

    public getLabelFromItem(item: DropDownOptionType): string {
        return item.existingUserInfo
            ? `${item.existingUserInfo.firstName} ${item.existingUserInfo.lastName}`
            : `${item.newUserInfo.firstName} ${item.newUserInfo.lastName}`;
    }

    public getNewUserLength() {
        return this.optionsList.filter((data) => {
            return data.newUserInfo;
        }).length;
    }

    private appendNewUserListItem(newUserInfo: NewUserType) {
        const newItem: DropDownOptionType = {
            id: newUserInfo.email,
            existingUserInfo: null,
            newUserInfo,
        };
        this.optionsList.push(newItem);
        const newItemNgOption = this.ngSelect.itemsList.addItem(newItem);
        newItemNgOption.disabled = true;
        setTimeout(() => {
            const selectedItems = this.formControl.value;
            selectedItems?.push(newUserInfo.email);
            this.formControl.patchValue(selectedItems);
        }, 0);
    }

    public addNewUserFlow() {
        const moreInfoIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-user-plus',
        };

        const modalData: IModalData = {
            modalName: 'Create User',
            modalIcon: moreInfoIcon,
            modalType: ModalType.MIDDLE,
            modalAutoHeight: true,
            modalWidthVw: 70,
            sourceId: this.widgetUniqueIdentity,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: IssueAuditAddNewUserStepOneComponent,
                        payload: {
                            data: {
                                customerId: this.customerId,
                            },
                        },
                    },
                    stepName: 'USER INFORMATION',
                },
                {
                    stepData: {
                        componentToLoad: IssueAuditAddNewUserStepTwoComponent,
                        payload: {
                            data: {
                                assessmentName: this.assessmentName,
                                domainId: this.domainId,
                            },
                        },
                    },
                    stepName: 'PERMISSIONS',
                },
            ],
            closeCallback: (res) => {
                if (res) {
                    this.appendNewUserListItem(res);
                }
            },
        };
        this.modalService.openModal(modalData);
    }
}
