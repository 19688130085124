import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { DateField } from 'src/app/shared/enums/AppearanceType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import {
    IAssesseInfo,
    IAssessmentData,
    IAssessorInfo
} from '../../assessee-view/assessment-more-info-modal/assessment-more-info-modal.component';
import { Helper } from './../../../../classes/Helper';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { IssueAuditSelectUserRequestPayloadPart } from '../issue-audit-select-user-dropdown/issue-audit-select-user-dropdown.component';

enum AssessmentType {
    ASSESSOR = 'Independent',
    BOTHASSESSORANDASSESSE = 'Dependent'
}
export enum DependentType {
    NEW = 'New',
    EXISTING = 'Existing'
}
@Component({
    selector: 'app-issue-assessment',
    templateUrl: './issue-assessment.component.html',
    styleUrls: ['./issue-assessment.component.sass']
})
export class IssueAssessmentComponent implements OnInit {
    assesseeInfoFormGenInput: IFormGeneratorInput = null;
    assesseeInfoFormRef: FormGroup;
    rootUserInfoFormGenInput: IFormGeneratorInput = null;
    rootUserInfoFormRef: FormGroup;
    assessorInfoFormGenInput: IFormGeneratorInput = null;
    assessorInfoFormRef: FormGroup;
    selectFormGenInput: IFormGeneratorInput;
    existingFormGenInput: IFormGeneratorInput;
    selectFormGroupRef: FormGroup;
    onlyAssessorFormGenInput: IFormGeneratorInput;
    assessorExistingDetailsFormGenInput: IFormGeneratorInput;
    assessmentFormGroup: FormGroup;
    existingFormRef: FormGroup;
    onlyAssessorFormGroupRef: FormGroup;
    assessorExistingDetailsFormGrpRef: FormGroup;
    widgetRef: Widget;
    assesseInfo: IAssesseInfo;
    assessorInfo: IAssessorInfo;
    assessmentType: string;
    dependentType: string;
    template: string;
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    secondStepReponse;
    issueAssessmentButtonGenInput: IButtonGeneratorInput;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    modalLoader: boolean = false;
    domainIds = [];
    emailValidations = [];
    selectedTagsData = [];
    availableTagsData = [];
    finalTagsData = [];
    tagsAssigned;
    tagsToBeDeleted = [];
    emailSubject: string;
    emailContent: string;
    nextButtonGenInput: IButtonGeneratorInput;
    assessmentName: string;
    issueButtonGenInput: IButtonGeneratorInput;
    stepData: IAssessmentData;
    isEdit: boolean = false;
    eAssessmentType = AssessmentType;
    eDependentType = DependentType;
    assessorType: string;
    selectedAssessorType = DependentType;
    /**
     * To be used for user selection after selecting existing customer
     */
    public selectedCustomerItemId: null | string = null;
    /**
     * To be used for user selection after selecting existing customer
     */
    public selectedCustomerDomainId: null | string = null;
    /**
     * For checking user dropdown validation status
     */
    public issueAuditDependentUserFormGroup: FormGroup;
    /**
     * partial payload gathered from user dropdown
     */
    public issueAuditSelectUserRequestPayloadPart: IssueAuditSelectUserRequestPayloadPart =
        {
            assesseUserIds: [],
            newUser: [],
        };

    constructor(
        private modalData: ModalInjectedData,
        private configCacheService: ConfigCacheService,
        private listHttpService: ListHttpService,
        private multiStepFormService: MultiStepFormService,
        private cdref: ChangeDetectorRef
    ) {
        this.template = this.configCacheService.assessmentTemplate;
        this.widgetRef = this.modalData.data.widgetRef;
        this.modalData.data['checkIsUnSavedData'] =
            this.checkIsUnSavedData.bind(this);
        this.assessmentName = this.modalData['data']['issueData']
            ? this.modalData['data']['issueData']['assessmentName']
            : this.widgetRef.widgetData.widgetInfo.text
            ? this.widgetRef.widgetData.widgetInfo.text
            : '';
        this.isEdit = this.modalData.data['isEdit'];
        if (this.modalData.data && this.modalData.data['isEdit']) {
            this.stepData = this.modalData.data['stepData'];
            this.tagsAssigned = this.modalData.data['stepData']['tags'];
        }

        if (
            this.modalData.data.showInfoData &&
            this.modalData.data.showInfoData['assesseInfo']
        ) {
            this.assesseInfo = this.modalData.data.showInfoData['assesseInfo'];
        }
        if (
            this.modalData.data.showInfoData &&
            this.modalData.data.showInfoData['assessorInfo']
        ) {
            this.assessorInfo =
                this.modalData.data.showInfoData['assessorInfo'];
        }
    }

    private checkIsUnSavedData(): boolean {
        let isTouched: boolean = false;
        const assessorInfoFormRefCheck = () => {
            for (let [_, control] of Object.entries(
                this.assessorInfoFormRef?.controls || {}
            )) {
                if (control.touched) {
                    isTouched = true;
                    break;
                }
            }
        };
        const newTagsCheck = () => {
            if (this.selectedTagsData.length) {
                isTouched = true;
            }
        };
        if (this.isEdit) {
            assessorInfoFormRefCheck();
            const oldTags = this.modalData.data?.['stepData']?.['tags'];
            const newTags = this.tagsAssigned;
            if (newTags?.length > 0) {
                if (newTags?.length !== oldTags?.length) {
                    isTouched = true;
                } else {
                    const isEqual = newTags?.every((newElement, i) => {
                        if (
                            newElement.name !== oldTags[i].name ||
                            newElement.colorCode !== oldTags[i].colorCode
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    });
                    isTouched = isEqual ? isTouched : true;
                }
            }
        } else {
            if (this.assessmentType == this.eAssessmentType.ASSESSOR) {
                assessorInfoFormRefCheck();
                for (let [_, control] of Object.entries(
                    this.onlyAssessorFormGroupRef?.controls || {}
                )) {
                    if (control.touched) {
                        isTouched = true;
                        break;
                    }
                }
                newTagsCheck();
            } else if (
                this.assessmentType ==
                    this.eAssessmentType.BOTHASSESSORANDASSESSE &&
                this.dependentType == this.eDependentType.NEW
            ) {
                for (let [_, control] of Object.entries(
                    this.assesseeInfoFormRef?.controls || {}
                )) {
                    if (control.touched) {
                        isTouched = true;
                        break;
                    }
                }
                newTagsCheck();
                for (let [_, control] of Object.entries(
                    this.rootUserInfoFormRef?.controls || {}
                )) {
                    if (control.touched) {
                        isTouched = true;
                        break;
                    }
                }
                assessorInfoFormRefCheck();
            } else if (
                this.assessmentType ==
                    this.eAssessmentType.BOTHASSESSORANDASSESSE &&
                this.dependentType == this.eDependentType.EXISTING
            ) {
                for (let [_, control] of Object.entries(
                    this.existingFormRef?.controls || {}
                )) {
                    if (control.touched) {
                        isTouched = true;
                        break;
                    }
                }
                for (let [_, control] of Object.entries(
                    this.issueAuditDependentUserFormGroup?.controls || {}
                )) {
                    if (control.touched) {
                        isTouched = true;
                        break;
                    }
                }
                newTagsCheck();
                assessorInfoFormRefCheck();
            }
        }

        return isTouched;
    }

    ngOnInit(): void {
        this.nextButtonGenInput = {
            buttonName: this.isEdit ? 'Update' : 'Issue',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.RAISED,
            function: (buttonRef: IButtonGeneratorInput) => {
                if (this.isEdit) {
                    this.updateAssessor(buttonRef);
                } else {
                    this.goToNextStep(buttonRef);
                }
            },
            showLoader: true
        };
        this.issueButtonGenInput = {
            buttonName: 'Issue',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            showLoader: true,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.issueAssessment(buttonRef);
            }
        };
        if (
            this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .has(1)
        ) {
            if (
                this.multiStepFormService.stepData
                    .get(this.modalData.modalId)
                    .get(1).currentCustomer === true
            ) {
                this.dependentType = this.eDependentType.EXISTING;
            } else {
                this.dependentType = this.eDependentType.NEW;
            }
            this.assessmentType = this.eAssessmentType.BOTHASSESSORANDASSESSE;
            this.stepData = this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .get(1);
        }
        this.generateForm();
        if (this.isEdit) {
            this.selectedTagsData = this.modalData.data.stepData['tags'];
        }
        if (!this.isEdit)
            this.selectedTagsData = this.multiStepFormService.stepData
                .get(this.modalData.modalId)
                .get(1)?.tags
                ? this.multiStepFormService.stepData
                      .get(this.modalData.modalId)
                      .get(1).tags
                : [];
    }
    generateForm() {
        let auditDueDateValue = '';
        let issueDateValue = '';
        if (this.stepData && this.stepData['auditDueDate']) {
            const date = this.stepData['auditDueDate'];
            const timeZoneValue = date.split(' ')[date.split(' ').length - 1];
            auditDueDateValue = date.split(timeZoneValue)[0].trim();

            const timeValue = this.stepData['auditDueDate'].includes(';')
                ? this.stepData['auditDueDate'].split('; ')[1].split(' ')[0]
                : this.stepData['auditDueDate'].split(' ')[1];
            const timeValueUpdate = moment(timeValue, 'HH:mm').format('hh:mm');
            auditDueDateValue = auditDueDateValue.replace(
                timeValue,
                timeValueUpdate
            );
        }

        if (this.isEdit && this.stepData['issueDate']) {
            const issueDate = String(this.stepData['issueDate']);
            const issueTimeZoneValue =
                issueDate.split(' ')[issueDate.split(' ').length - 1];
            issueDateValue = issueDate.split(issueTimeZoneValue)[0].trim();
            const issueTimeValue = issueDate.includes(';')
                ? issueDate.split('; ')[1].split(' ')[0]
                : this.stepData['auditDueDate'].split(' ')[1];
            const issueTimeValueUpdate = moment(issueTimeValue, 'HH:mm').format(
                'hh:mm'
            );
            issueDateValue = issueDateValue.replace(
                issueTimeValue,
                issueTimeValueUpdate
            );
        }

        this.onlyAssessorFormGenInput = {
            formName: 'Only Assessor Form',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Company Name',
                    name: 'companyName',
                    required: true,
                    appearance: 'legacy',
                    showLabel: true,
                    placeholder: 'Enter Company Name',
                    fieldType: FilterType.TEXT,
                    value:
                        this.stepData && this.stepData['companyName']
                            ? this.stepData['companyName']
                            : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Company Name is required',
                        },
                        {
                            validator: CustomValidators.noWhiteSpace,
                            errorMessage:
                                'Please enter a number or a special character or an alphabet',
                        },
                    ],
                },
            ],
        };
        this.assessorExistingDetailsFormGenInput = {
            formName: 'Only Assessor Form',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Company Name',
                    name: 'companyName',
                    required: true,
                    appearance: 'legacy',
                    showLabel: true,
                    placeholder: 'Select Company Name',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    apiInfo: this.listHttpService.getExisitingCompany(
                        this.widgetRef.widgetData.widgetId
                    ),
                    value:
                        this.stepData && this.stepData['companyName']
                            ? this.stepData['companyName']
                            : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Company Name is required'
                        }
                    ]
                }
            ]
        };
        this.getSelectedPmos((pmos, err) => {
            if (pmos) {
                this.assesseeInfoFormGenInput = {
                    formName: 'Issue Assessment',
                    submitButton: null,
                    state: FormState.CREATE,
                    fields: [
                        {
                            label: 'Domain ID',
                            placeholder: 'Enter the Domain ID',
                            name: 'domainId',
                            required: true,
                            showLabel: true,
                            appearance: 'legacy',
                            fieldType: FilterType.TEXT,
                            value:
                                this.stepData && this.stepData['domainId']
                                    ? this.stepData['domainId']
                                    : '',
                            validations: [
                                {
                                    validator: CustomValidators.required,
                                    errorMessage: 'Domain ID is required',
                                },
                                {
                                    validator:
                                        CustomValidators.controlValueNotIn(
                                            this.domainIds
                                        ),
                                    errorMessage: 'Domain ID already exits',
                                },
                                {
                                    validator:
                                        CustomValidators.noSpecialCharacters,
                                    errorMessage:
                                        'Domain ID cannot contain special character',
                                },
                            ],
                        },
                        {
                            label: 'Company Name',
                            name: 'companyName',
                            required: true,
                            appearance: 'legacy',
                            showLabel: true,
                            placeholder: 'Enter Company Name',
                            fieldType: FilterType.TEXT,
                            value:
                                this.stepData && this.stepData['companyName']
                                    ? this.stepData['companyName']
                                    : '',
                            validations: [
                                {
                                    validator: CustomValidators.required,
                                    errorMessage: 'Company Name is required',
                                },
                                {
                                    validator: CustomValidators.noWhiteSpace,
                                    errorMessage:
                                        'Please enter a number or a special character or an alphabet',
                                },
                            ],
                        },
                        {
                            label: 'Select Region',
                            name: 'dataSourceId',
                            required: true,
                            appearance: 'legacy',
                            showLabel: true,
                            placeholder: 'Select Region',
                            fieldType: FilterType.DROPDOWN_SINGLE,
                            value:
                                this.stepData && this.stepData['dataSourceId']
                                    ? this.stepData['dataSourceId']
                                    : '',
                            apiInfo: this.listHttpService.cacRegionsApiInfo,
                            validations: [
                                {
                                    validator: CustomValidators.required,
                                    errorMessage: 'Region is required',
                                },
                            ],
                        },
                    ],
                };
                this.rootUserInfoFormGenInput = {
                    formName: '',
                    submitButton: null,
                    state: FormState.CREATE,
                    fields: [
                        {
                            label: 'First Name',
                            required: true,
                            appearance: 'legacy',
                            showLabel: true,
                            placeholder: 'Enter First Name',
                            name: 'firstName',
                            fieldType: FilterType.TEXT,
                            value:
                                this.stepData && this.stepData['firstName']
                                    ? this.stepData['firstName']
                                    : '',
                            validations: [
                                {
                                    validator: CustomValidators.required,
                                    errorMessage: 'First Name is required',
                                },
                                {
                                    validator: CustomValidators.noWhiteSpace,
                                    errorMessage:
                                        'Please enter a number or a special character or an alphabet',
                                },
                            ],
                        },
                        {
                            label: 'Last Name',
                            required: true,
                            appearance: 'legacy',
                            showLabel: true,
                            placeholder: 'Enter Last Name',
                            name: 'lastName',
                            value:
                                this.stepData && this.stepData['lastName']
                                    ? this.stepData['lastName']
                                    : '',
                            fieldType: FilterType.TEXT,
                            validations: [
                                {
                                    validator: CustomValidators.required,
                                    errorMessage: 'Last Name is required',
                                },
                                {
                                    validator: CustomValidators.noWhiteSpace,
                                    errorMessage:
                                        'Please enter a number or a special character or an alphabet',
                                },
                            ],
                        },
                        {
                            label: 'Email ID',
                            required: true,
                            appearance: 'legacy',
                            showLabel: true,
                            placeholder: 'Enter Email Id',
                            name: 'email',
                            fieldType: FilterType.TEXT,
                            value:
                                this.stepData && this.stepData['email']
                                    ? this.stepData['email']
                                    : '',
                            validations: [
                                {
                                    validator: CustomValidators.required,
                                    errorMessage: 'Email is required',
                                },
                                {
                                    validator: CustomValidators.email,
                                    errorMessage: 'Email is invalid',
                                },
                            ],
                        },
                        {
                            label: 'Contact Number',
                            appearance: 'legacy',
                            showLabel: true,
                            name: 'phoneNumber',
                            fieldType: FilterType.NUMBER,
                            required: false,
                            placeholder: 'Enter Contact Number',
                            value:
                                this.stepData && this.stepData['phoneNumber']
                                    ? this.stepData['phoneNumber']
                                    : null,
                        },
                        {
                            label: 'Password',
                            placeholder: 'Enter Password',
                            fieldType: FilterType.PASSWORD,
                            appearance: 'legacy',
                            showLabel: true,
                            required: true,
                            validations: Helper.getPasswordValidators(),
                            value:
                                this.stepData && this.stepData['password']
                                    ? this.stepData['password']
                                    : '',
                            suffixIcon: {
                                iconData: {
                                    type: IconType.MATICON,
                                    class: 'edit',
                                },
                                hoverText: 'Generate Password',
                                function: this.generatePassword.bind(this),
                            },
                            name: 'password',
                            extraClass: 'password-field',
                        },
                        {
                            label: 'Confirm Password',
                            placeholder: 'Confirm Password',
                            name: 'confirmPassword',
                            fieldType: FilterType.PASSWORD,
                            showLabel: true,
                            appearance: 'legacy',
                            required: true,
                            validations: Helper.getPasswordValidators(
                                'equal',
                                'password'
                            ),
                            value:
                                this.stepData &&
                                this.stepData['confirmPassword']
                                    ? this.stepData['confirmPassword']
                                    : '',
                            extraClass: 'password-field',
                        },
                    ],
                };
                this.assessorInfoFormGenInput = {
                    formName: '',
                    submitButton: null,
                    state: FormState.CREATE,
                    fields: [
                        {
                            label: 'Select Assessor',
                            placeholder: 'Select Assessor',
                            name: 'evaluaterUserIds',
                            fieldType: FilterType.DROPDOWN_MULTIPLE,
                            required: this.isEdit ? true : false,
                            appearance: 'legacy',
                            showLabel: true,
                            apiInfo: this.listHttpService.userListApi,
                            value:
                                this.stepData &&
                                this.stepData['evaluaterUserIds']
                                    ? this.stepData['evaluaterUserIds']
                                    : this.stepData &&
                                      this.stepData['selectedAssessor']
                                    ? this.stepData['selectedAssessor']
                                    : [],
                            validations: this.isEdit
                                ? [
                                      {
                                          validator: CustomValidators.required,
                                          errorMessage:
                                              'This field is required.'
                                      }
                                  ]
                                : null,
                            afterResponse: (response) => {
                                this.secondStepReponse = response;
                                const listData: IDropdownData[] = (
                                    response as IDropdownData[]
                                ).map((data: IDropdownData) => {
                                    return data;
                                });
                                this.assessorInfoFormGenInput.fields[0].listData =
                                    listData;
                                this.assessorInfoFormGenInput =
                                    Helper.dereference(
                                        this.assessorInfoFormGenInput
                                    );
                            }
                        },
                        {
                            label: 'Select PC',
                            placeholder: 'Select Program Coordinator',
                            name: 'pmoUsers',
                            fieldType: FilterType.DROPDOWN_MULTIPLE,
                            required: this.isEdit ? true : false,
                            appearance: 'legacy',
                            showLabel: true,
                            listData: pmos['userResponse'],
                            value: this.isEdit
                                ? this.stepData && this.stepData['selectedPMOs']
                                : (pmos['seletedPMOs'] as IDropdownData[]).map(
                                      (data) => data.id
                                  ),
                            validations: this.isEdit
                                ? [
                                      {
                                          validator: CustomValidators.required,
                                          errorMessage:
                                              'This field is required.'
                                      }
                                  ]
                                : null
                        },
                        {
                            label: 'Job Id',
                            placeholder: 'Enter Job Id',
                            name: 'jobId',
                            showLabel: true,
                            fieldType: FilterType.TEXT,
                            appearance: 'legacy',
                            required: false,
                            value:
                                this.stepData && this.stepData['jobId']
                                    ? this.stepData['jobId']
                                    : '',
                        },
                        {
                            label: 'Date of Assessment',
                            placeholder: 'dd/mm/yyyy',
                            name: 'auditDueDate',
                            fieldType: FilterType.DATE,
                            required: false,
                            appearance: DateField.TYPE_5,
                            showLabel: true,
                            minDate:
                                this.isEdit &&
                                this.stepData['issueDate'].toString() !== '-'
                                    ? moment(issueDateValue).toDate()
                                    : moment().toDate(),
                            value:
                                this.stepData &&
                                this.stepData['auditDueDate'] &&
                                this.stepData['auditDueDate'] !== '-'
                                    ? this.stepData['auditDueDate'].includes(
                                          ';'
                                      )
                                        ? moment(auditDueDateValue).toDate()
                                        : moment(
                                              this.stepData[
                                                  'auditDueDate'
                                              ].split(' ')[0]
                                          ).toDate()
                                    : ''
                        },
                        {
                            name: 'timeOfAssesment',
                            fieldType: FilterType.TIME,
                            label: 'Time of Assesment',
                            placeholder: '',
                            value:
                                this.stepData &&
                                this.stepData['auditDueDate'] &&
                                this.stepData['auditDueDate'] !== '-'
                                    ? this.stepData['auditDueDate'].includes(
                                          ';'
                                      )
                                        ? `${
                                              auditDueDateValue
                                                  .split('; ')[1]
                                                  .split(' ')[0]
                                          } ${
                                              auditDueDateValue
                                                  .split('; ')[1]
                                                  .split(' ')[1]
                                          }`
                                        : `${
                                              this.stepData[
                                                  'auditDueDate'
                                              ].split(' ')[1]
                                          } ${
                                              this.stepData[
                                                  'auditDueDate'
                                              ].split(' ')[2]
                                          }`
                                    : '12:00 AM',
                            showLabel: true,
                            appearance: 'legacy',
                            required: false
                        },
                        {
                            name: 'timeZone',
                            fieldType: FilterType.DROPDOWN_SINGLE,
                            label: 'Time Zone',
                            placeholder: 'Select Timezone',
                            value:
                                this.stepData &&
                                this.stepData['auditDueDate'] !== '-'
                                    ? this.stepData['auditDueDate'].split(' ')[
                                          this.stepData['auditDueDate'].split(
                                              ' '
                                          ).length - 1
                                      ]
                                    : 'UTC',
                            showLabel: true,
                            appearance: 'legacy',
                            apiInfo: this.listHttpService.newTimeZoneApi,
                            responseValueKey: 'dataList',
                            required: false
                        },
                        {
                            fieldType: FilterType.CHECKBOX,
                            label: 'Mark selected PC as defaults for all assessments',
                            extraClass: 'accent-checkbox',
                            name: 'defaultPMOUsers',
                            fieldStyle: {
                                'margin-top': '15px',
                                'margin-bottom': '20px',
                                width: '100%'
                            },
                            value: this.stepData
                                ? this.stepData['defaultPMOUsers']
                                : false,
                            placeholder: '',
                            required: false
                        }
                    ]
                };
                this.selectFormGenInput = {
                    formName: '',
                    submitButton: null,
                    state: FormState.CREATE,
                    fields: [
                        {
                            label: '',
                            placeholder: '',
                            name: 'assessmentSelection',
                            required: false,
                            showLabel: true,
                            appearance: 'legacy',
                            fieldType: FilterType.RADIO,
                            listData: [
                                {
                                    id: 'assessor',
                                    label: 'Assessor'
                                },
                                {
                                    id: 'bothAssessorAndAssesse',
                                    label: 'Both Assessor & Assessee'
                                }
                            ]
                        }
                    ]
                };
                this.existingFormGenInput = {
                    formName: 'Existing Customer Form',
                    submitButton: null,
                    state: FormState.CREATE,
                    fields: [
                        {
                            label: 'Select Customer',
                            name: 'currentCustomerTenantId',
                            required: true,
                            appearance: 'legacy',
                            showLabel: true,
                            placeholder: 'Select Customer',
                            fieldType: FilterType.DROPDOWN_SINGLE,
                            value:
                                this.stepData &&
                                this.stepData['currentCustomerTenantId']
                                    ? this.stepData['currentCustomerTenantId'].id
                                    : '',
                            validations: [
                                {
                                    validator: CustomValidators.required,
                                    errorMessage: 'Customer Name is required'
                                }
                            ],
                            apiInfo:
                                this.listHttpService.assessmentCustomerListInfo,
                            getKey: null,
                            onChange: (selectedCustomerItem) => {
                                this.selectedCustomerItemId = selectedCustomerItem?.id || null;
                                this.selectedCustomerDomainId  = selectedCustomerItem?.domainId	 || null;
                            }
                        }
                    ]
                };
            }
            if (err) {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    err,
                    'Something went wrong'
                );
            }
        });
    }
    checkAssessmentType(value) {
        this.assessmentType = value;
        if (
            this.assessmentType === this.eAssessmentType.ASSESSOR &&
            this.assessorInfoFormGenInput
        ) {
            this.assessorInfoFormGenInput.fields[0].required = true;
            this.assessorInfoFormGenInput.fields[0].validations = [
                {
                    validator: CustomValidators.required,
                    errorMessage: 'Assessor is required'
                }
            ];
        } else {
            if (this.assesseeInfoFormGenInput)
                this.assessorInfoFormGenInput.fields[0].required = false;
        }
        this.cdref.detectChanges();
    }
    updateTags($event) {
        this.tagsAssigned = $event['tagsData'];
        if ($event['tagToBeDeleted']) {
            this.tagsToBeDeleted.push($event['tagToBeDeleted']);
        }

        if ($event['deleteTags']) {
            this.prepareFinalTags($event);
        }
    }
    prepareFinalTags($event) {
        let tags = [...this.tagsAssigned];
        tags = Helper.removeDuplicateObjetsFromArray(tags);
        if (this.tagsToBeDeleted.length) {
            this.tagsToBeDeleted.forEach((tagsToBeDeleted) => {
                const tagsIndex = tags.findIndex(
                    (tag) => tag.name === this.tagsToBeDeleted
                );
                if (tagsIndex > -1) {
                    tags = Helper.removeIndexfromArray(tagsIndex, tags);
                }
            });
        }
        this.selectedTagsData = tags;

        this.finalTagsData['tags'] = tags;
        if ($event['callback']) {
            $event['callback']('SUCCESS');
        }
    }
    generatePassword() {
        let password = Helper.generateUniqueKey(16);
        password += 'a@B1';
        this.rootUserInfoFormRef.get('password').setValue(password);
        this.rootUserInfoFormRef.get('confirmPassword').setValue(password);
    }
    assignAssessment(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        Helper.markAllFieldAsTouched(this.assesseeInfoFormRef);
        Helper.markAllFieldAsTouched(this.rootUserInfoFormRef);
        this.rootUserInfoFormRef
            .get('confirmPassword')
            .updateValueAndValidity();

        if (this.assesseeInfoFormRef.invalid) {
            return;
        }
        if (this.rootUserInfoFormRef.invalid) {
            this.rootUserInfoFormRef.updateValueAndValidity();
            return;
        }
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.action
        );
        apiArgs.input = {
            ...apiArgs.input,
            widgetId: this.widgetRef.widgetData.widgetId
        };
        apiArgs.function = (res) => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                res.message,
                false,
                '',
                {
                    duration: 5000
                }
            );
            this.widgetRef.refreshWidget();
            this.widgetRef.modalService.closeModal(
                null,
                this.modalData.modalId
            );
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            this.widgetRef.modalService.closeModal(
                null,
                this.modalData.modalId
            );
        };
        buttonRef.loader = true;

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    goToNextStep(buttonRef?: IButtonGeneratorInput) {
        if (this.existingFormRef && this.existingFormRef.invalid) {
            Helper.markAllFieldAsTouched(this.existingFormRef);
            return;
        }
        if (
            this.issueAuditDependentUserFormGroup &&
            this.issueAuditDependentUserFormGroup.invalid
        ) {
            Helper.markAllFieldAsTouched(this.issueAuditDependentUserFormGroup);
            return;
        }
        if (this.assesseeInfoFormRef)
            Helper.markAllFieldAsTouched(this.assesseeInfoFormRef);
        if (this.assessorInfoFormRef)
            Helper.markAllFieldAsTouched(this.assessorInfoFormRef);
        if (this.rootUserInfoFormRef) {
            Helper.markAllFieldAsTouched(this.rootUserInfoFormRef);
            this.rootUserInfoFormRef
                .get('confirmPassword')
                .updateValueAndValidity();
        }
        if (
            this.assesseeInfoFormRef &&
            this.assesseeInfoFormRef.invalid &&
            this.dependentType === this.eDependentType.NEW
        ) {
            this.assesseeInfoFormRef.updateValueAndValidity();
            return;
        }
        if (
            this.rootUserInfoFormRef &&
            this.rootUserInfoFormRef.invalid &&
            this.dependentType === this.eDependentType.NEW
        ) {
            this.rootUserInfoFormRef.updateValueAndValidity();
            return;
        }
        if (this.assessorInfoFormRef && this.assessorInfoFormRef.invalid) {
            this.assessorInfoFormRef.updateValueAndValidity();
            return;
        }
        this.issueAssessmentDependentCase(
            Helper.cloneDeep(this.prepareStepData()),
            buttonRef
        );
    }

    prepareStepData() {
        const date = this.assessorInfoFormRef.value.auditDueDate;
        const time = this.assessorInfoFormRef.value.timeOfAssesment;
        const timeZone = this.assessorInfoFormRef.value.timeZone;
        let finalDate = '';
        let momentDateValue = '';
        if (date) {
            if (time && timeZone) {
                finalDate = `${moment(date).format('L')} ${time} ${timeZone}`;
                momentDateValue = `${moment(date).format(
                    'ddd, MMM DD, YYYY'
                )} ${time} ${timeZone}`;
            } else if (time && !timeZone) {
                finalDate = `${moment(date).format('L')} ${time} ${'UTC'}`;
                momentDateValue = `${moment(date).format(
                    'ddd, MMM DD, YYYY'
                )} ${time} ${timeZone}`;
            }
        } else {
            finalDate = '';
        }
        this.assessorInfoFormRef.value.auditDueDate = finalDate;
        this.assessorInfoFormRef.value.finalDateValue = momentDateValue;
        const assessorNames = [];
        this.secondStepReponse &&
            this.secondStepReponse.forEach((res) => {
                if (
                    this.assessorInfoFormRef.value.evaluaterUserIds.includes(
                        res.id
                    )
                ) {
                    assessorNames.push(res.label);
                }
            });
        if (this.dependentType === this.eDependentType.NEW) {
            return {
                ...this.assesseeInfoFormRef.getRawValue(),
                ...this.rootUserInfoFormRef.getRawValue(),
                ...this.assessorInfoFormRef.value,
                assessmentType: this.assessmentType,
                assessmentName: this.assessmentName,
                assessorNames: assessorNames,
                currentCustomer: false,
                ...this.finalTagsData
            };
        } else if (this.dependentType === this.eDependentType.EXISTING) {
            return {
                ...this.assessorInfoFormRef.value,
                assessmentType: this.assessmentType,
                assessmentName: this.assessmentName,
                assessorNames: assessorNames,
                currentCustomer: true,
                ...this.existingFormRef.value,
                ...this.finalTagsData,
                ...this.issueAuditSelectUserRequestPayloadPart,
            };
        }
    }
    updateAssessor(buttonRef) {
        if (buttonRef.loader) {
            return;
        }
        Helper.markAllFieldAsTouched(this.assessorInfoFormRef);
        if (this.assessorInfoFormRef.invalid) {
            this.assessorInfoFormRef.updateValueAndValidity();
            return;
        }
        if (
            this.widgetRef.widgetData.widgetInfo &&
            !this.widgetRef.widgetData.widgetInfo.update
        ) {
            return;
        }
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.update
        );
        apiArgs.input = this.assessorInfoFormRef.value;
        apiArgs.input['tags'] = this.tagsAssigned;
        const date = this.assessorInfoFormRef.value.auditDueDate;
        const time = this.assessorInfoFormRef.value.timeOfAssesment;
        const timeZone = this.assessorInfoFormRef.value.timeZone;
        if (date) {
            if (time && timeZone) {
                apiArgs.input['auditDueDate'] = `${moment(date).format(
                    'L'
                )} ${time} ${timeZone}`;
            } else if (time && !timeZone) {
                apiArgs.input['auditDueDate'] = `${moment(date).format(
                    'L'
                )} ${time} ${'UTC'}`;
            }
        } else {
            apiArgs.input['auditDueDate'] = '-';
        }
        apiArgs.input['timeOfAssesment'] = time;
        apiArgs.input['timeZone'] = timeZone ? timeZone : 'UTC';
        apiArgs.function = () => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'Assessment Updated Successfully.'
            );
            this.widgetRef.refreshWidget();
            this.widgetRef.modalService.closeModal(
                null,
                this.modalData.modalId
            );
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            this.widgetRef.modalService.closeModal(
                null,
                this.modalData.modalId
            );
        };
        apiArgs.url = apiArgs.url.replace(
            '{assessment-id}',
            this.stepData.assessmentId
        );
        buttonRef.loader = true;

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    issueAssessment(buttonRef) {
        if (buttonRef.loader) {
            return;
        }
        Helper.markAllFieldAsTouched(this.onlyAssessorFormGroupRef);
        Helper.markAllFieldAsTouched(this.assessorInfoFormRef);
        if (this.onlyAssessorFormGroupRef.invalid) {
            this.onlyAssessorFormGroupRef.updateValueAndValidity();
            return;
        }
        if (this.assessorInfoFormRef.invalid) {
            this.assessorInfoFormRef.updateValueAndValidity();
            return;
        }
        buttonRef.loader = true;
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.action
        );

        const date = this.assessorInfoFormRef.value.auditDueDate;
        const time = this.assessorInfoFormRef.value.timeOfAssesment;
        const timeZoneVaue = this.assessorInfoFormRef.value.timeZone;
        let finalDate = '';
        if (date) {
            if (time && timeZoneVaue) {
                finalDate = `${moment(date).format(
                    'L'
                )} ${time} ${timeZoneVaue}`;
            } else if (time && !timeZoneVaue) {
                finalDate = `${moment(date).format('L')} ${time} ${'UTC'}`;
            }
        } else {
            finalDate = '';
        }

        apiArgs.input = {
            ...this.onlyAssessorFormGroupRef.value,
            evaluaterUserIds: this.assessorInfoFormRef.value.evaluaterUserIds,
            assessmentType: this.assessmentType,
            widgetId: this.widgetRef.widgetData.widgetId,
            jobId: this.assessorInfoFormRef.value.jobId,
            pmoUsers: this.assessorInfoFormRef.value.pmoUsers,
            defaultPMOUsers: this.assessorInfoFormRef.value.defaultPMOUsers
                ? this.assessorInfoFormRef.value.defaultPMOUsers
                : false,
            timeOfAssesment: time,
            timeZone: timeZoneVaue ? timeZoneVaue : 'UTC',
            auditDueDate: finalDate,
            ...this.finalTagsData
        };
        apiArgs.function = (res) => {
            if (res && res['jobId']) {
                buttonRef.loader = false;
                this.widgetRef.notificationsService.showSnackBar(
                    res.message,
                    false,
                    '',
                    {
                        duration: 3000
                    }
                );
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalData.modalId
                );
            } else {
                this.widgetRef.refreshWidget();
            }
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            this.widgetRef.modalService.closeModal(
                null,
                this.modalData.modalId
            );
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }


    /**
     * Skipping next step for email template as it is no longer required
     */
    private issueAssessmentDependentCase(
        inputData,
        buttonRef?: IButtonGeneratorInput,
    ): void {
        if (buttonRef?.loader) {
            return;
        }
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.action
        );
        delete inputData.assessorNames;
        inputData.defaultPMOUsers = inputData.defaultPMOUsers
            ? inputData.defaultPMOUsers
            : false;
            inputData.auditDueDate = inputData.auditDueDate.replace('null', '').trim();
        if (
            inputData.currentCustomerTenantId &&
            typeof inputData.currentCustomerTenantId === 'object'
        ) {
            inputData.currentCustomerTenantId = inputData.currentCustomerTenantId.id;
        }

        apiArgs.input = { ...inputData };
        apiArgs.input = {
            ...apiArgs.input,
            widgetId: this.widgetRef.widgetData.widgetId,
        };
        apiArgs.function = (res) => {
            if (res && res['jobId']) {
                buttonRef.loader = false;
                this.widgetRef.notificationsService.showSnackBar(
                    res.message,
                    false,
                    '',
                    {
                        duration: 3000,
                    }
                );
                this.widgetRef.modalService.closeModal(
                    null,
                    this.modalData.modalId
                );
            } else {
                this.widgetRef.refreshWidget();
            }
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(this.widgetRef.notificationsService, error);
            this.widgetRef.modalService.closeModal(
                null,
                this.modalData.modalId
            );
        };
        buttonRef.loader = true;

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    getSelectedPmos(cb) {
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.listHttpService.pmoListApi
        );
        apiArgs.function = (res) => {
            if (cb) {
                cb(res, null);
            }
        };
        apiArgs.errorFunction = (err) => {
            if (cb) {
                cb(null, err);
            }
        };
        apiArgs.input = {};
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
