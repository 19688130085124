import { TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import {
    IButtonGeneratorInput,
    IMultiButtonOption,
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { WysiwygEditorField } from 'src/app/shared/enums/AppearanceType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IconType } from 'src/app/shared/enums/IconType';

@Component({
    selector: 'app-issue-audit-add-new-user-step-two',
    templateUrl: './issue-audit-add-new-user-step-two.component.html',
    styleUrls: ['./issue-audit-add-new-user-step-two.component.sass'],
})
export class IssueAuditAddNewUserStepTwoComponent implements OnInit {
    public modalLoader: boolean = true;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER,
    };
    previousStepData;
    template: string = '';
    emailContentFormGenInputs: IFormGeneratorInput = null;
    emailContentFormGroup: FormGroup;
    emailSubject: string;
    stepData: any = null;

    buttonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.multiStepFormService.previousStep(
                    this.modalInputData.modalId
                );
            },
        },
        {
            buttonName: 'Save',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            showLoader: true,
            function: () => {
                this.createUser();
            },
        },
    ];

    buttonOptions: IMultiButtonOption = {
        layout: {
            justifyContent: 'space-between',
        },
    };

    constructor(
        private configCacheService: ConfigCacheService,
        public modalInputData: ModalInjectedData,
        public multiStepFormService: MultiStepFormService,
        private notificationService: NotificationsService,
        private modalService: ModalService,
        private cdr: ChangeDetectorRef,
        private httpService: HttpService,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        this.previousStepData = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);
        this.getAssesseeEmailTemplate();
    }

    getAssesseeEmailTemplate() {
        this.modalLoader = true;
        const assessmentName = this.modalInputData.data['assessmentName'];
        const domainId = this.modalInputData.data['domainId'];
        const apiArgs: IHitApi = {
            url: ApiUrls.ASSESSMENT_USER_EMAIL_TEMPLATE,
            requestType: RequestType.GET,
            input: {},
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
            function: (data) => {
                this.configCacheService.assessmentTemplate = data.id;
                this.template = this.configCacheService.assessmentTemplate;
                const fullName = (
                    this.previousStepData.firstName +
                    ' ' +
                    (this.previousStepData.lastName
                        ? this.previousStepData.lastName
                        : '')
                ).trim();
                const titleCase = new TitleCasePipe().transform(fullName);
                this.template = this.template.replace(/{{user}}/g, titleCase);
                this.template = Helper.replaceAll(
                    this.template,
                    '<Assessment Name>',
                    assessmentName || '<Assessment Name>'
                );
                this.template = Helper.replaceAll(
                    this.template,
                    '{{email}}',
                    this.previousStepData.email
                );
                this.template = Helper.replaceAll(
                    this.template,
                    '{{password}}',
                    this.previousStepData.password
                );
                this.template = Helper.replaceAll(
                    this.template,
                    '<Domain Id>',
                    domainId || '<Domain Id>'
                );
                this.modalLoader = false;
                this.setUpBasis();
            },
            errorFunction: (error) => {
                this.modalLoader = false;
            },
        };
        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }

    setUpBasis() {
        this.cdr.detectChanges();
        this.emailContentFormGenInputs = {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Subject',
                    placeholder: '',
                    name: 'subject',
                    fieldType: FilterType.TEXT,
                    value: this.emailSubject ? this.emailSubject : '',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email Subject is required',
                        },
                    ],
                    appearance: 'legacy',
                    showLabel: true,
                },
                {
                    label: 'Email Content',
                    placeholder: '',
                    name: 'emailContent',
                    fieldType: FilterType.WYSIWYG_EDITOR,
                    appearance: WysiwygEditorField.TYPE_2,
                    showLabel: true,
                    value: this.template,
                },
            ],
        };
    }
    createUser() {
        Helper.markAllFieldAsTouched(this.emailContentFormGroup);
        if (this.emailContentFormGroup.invalid) {
            this.emailContentFormGroup.updateValueAndValidity();
            return;
        }

        const input = this.prepareInputs();
        this.notificationService.showSnackBar('New User has been added');
        this.modalService.closeModal(input, this.modalInputData.modalId);
    }

    prepareInputs() {
        const data = this.emailContentFormGroup.value;
        const step1Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);

        const inputs = {
            firstName: step1Data.firstName,
            lastName: step1Data.lastName,
            password: step1Data.password,
            confirmPassword: step1Data.password,
            email: step1Data.email,
            sendingMechanism: {
                sendViaEmail: true,
                emailRequest: {
                    emailTo: [step1Data.email],
                    subject: data.subject,
                    emailBody: data.emailContent,
                },
            },
        };
        return inputs;
    }
}
