import { PieComponent } from 'src/app/shared/components/widgets/2.0/pie/pie.component';
import { AclListingComponent } from '../components/widgets/1.0/acl-listing/acl-listing.component';
import { AdoptionMetricsComponent } from '../components/widgets/1.0/adoption-metrics/adoption-metrics.component';
import { AdvancedAutomationComponent } from '../components/widgets/1.0/advanced-automation/advanced-automation.component';
import { AggregateComponent } from '../components/widgets/1.0/aggregate/aggregate.component';
import { AlertListingComponent } from '../components/widgets/1.0/alert-listing/alert-listing.component';
import { AllocationChecksV2Component } from '../components/widgets/1.0/allocation-checks-v2/allocation-checks-v2.component';
import { AllocationChecksComponent } from '../components/widgets/1.0/allocation-checks/allocation-checks.component';
import { ArmJsonTemplatesListingComponent } from '../components/widgets/1.0/arm-json-templates-listing/arm-json-templates-listing.component';
import { AssessmentAssesseeComponent } from '../components/widgets/1.0/assessment-assessee/assessment-assessee.component';
import { AssessmentListingComponent } from '../components/widgets/1.0/assessment-listing/assessment-listing.component';
import { AssessmentUserAccessComponent } from '../components/widgets/1.0/assessment-user-access/assessment-user-access.component';
import { AutoRecommendationComponent } from '../components/widgets/1.0/auto-recommendation/auto-recommendation.component';
import { AutomationListingComponent } from '../components/widgets/1.0/automation-listing/automation-listing.component';
import { AutotaskIntegrationComponent } from '../components/widgets/1.0/autotask-integration/autotask-integration.component';
import { AutotaskTicketListingComponent } from '../components/widgets/1.0/autotask-ticket-listing/autotask-ticket-listing.component';
import { AwsCreateInstanceProvisioningComponent } from '../components/widgets/1.0/aws-create-instance-provisioning/aws-create-instance-provisioning.component';
import { AwsIntegrationComponent } from '../components/widgets/1.0/aws-integration/aws-integration.component';
import { AzureCreateDiskProvisioningComponent } from '../components/widgets/1.0/azure-create-disk-provisioning/azure-create-disk-provisioning.component';
import { AzureMcaIntegrationComponent } from '../components/widgets/1.0/azure-mca-integration/azure-mca-integration.component';
import { AzureResourceProvisioningComponent } from '../components/widgets/1.0/azure-resource-provisioning/azure-resource-provisioning.component';
import { AzureSponsorshipIntegrationComponent } from '../components/widgets/1.0/azure-sponsorship-integration/azure-sponsorship-integration.component';
import { BargraphComponent } from '../components/widgets/1.0/bargraph/bargraph.component';
import { BudgetListingComponent } from '../components/widgets/1.0/budget-listing/budget-listing.component';
import { CaIntegrationComponent } from '../components/widgets/1.0/ca-integration/ca-integration/ca-integration.component';
import { CreateWidgetJsonComponent } from '../components/widgets/1.0/create-widget-json/create-widget-json.component';
import { CspListingComponent } from '../components/widgets/1.0/csp-listing/csp-listing.component';
import { CustomComplianceListingComponent } from '../components/widgets/1.0/custom-compliance-listing/custom-compliance-listing.component';
import { CustomRequestWidgetComponent } from '../components/widgets/1.0/custom-request-widget/custom-request-widget.component';
import { CustomWidgetListingComponent } from '../components/widgets/1.0/custom-widget-listing/custom-widget-listing.component';
import { CustomerProfileComponent } from '../components/widgets/1.0/customer-profile/customer-profile.component';
import { DomainConfigurationComponent } from '../components/widgets/1.0/domain-configuration/domain-configuration.component';
import { EaIntegrationComponent } from '../components/widgets/1.0/ea-integration/ea-integration.component';
import { EaListingComponent } from '../components/widgets/1.0/ea-listing/ea-listing.component';
import { ExpandableTableComponent } from '../components/widgets/1.0/expandable-table/expandable-table.component';
import { FiltersConfigurationComponent } from '../components/widgets/1.0/filters-configuration/filters-configuration.component';
import { FreshdeskIntegrationComponent } from '../components/widgets/1.0/freshdesk-integration/freshdesk-integration.component';
import { FreshdeskListingComponent } from '../components/widgets/1.0/freshdesk-listing/freshdesk-listing.component';
import { FreshserviceIntegrationComponent } from '../components/widgets/1.0/freshservice-integration/freshservice-integration.component';
import { FreshserviceListingComponent } from '../components/widgets/1.0/freshservice-listing/freshservice-listing.component';
import { GcpIntegrationComponent } from '../components/widgets/1.0/gcp-integration/gcp-integration.component';
import { GcpListingComponent } from '../components/widgets/1.0/gcp-listing/gcp-listing.component';
import { GeneralConfigurationComponent } from '../components/widgets/1.0/general-configuration/general-configuration.component';
import { GmailIntegrationComponent } from '../components/widgets/1.0/gmail-integration/gmail-integration.component';
import { IssueAssessmentListingComponent } from '../components/widgets/1.0/issue-assessment-listing/issue-assessment-listing.component';
import { LoginMetricsComponent } from '../components/widgets/1.0/login-metrics/login-metrics.component';
import { ManagedReportListingComponent } from '../components/widgets/1.0/managed-report-listing/managed-report-listing.component';
import { NagiosIntegrationComponent } from '../components/widgets/1.0/nagios-integration/nagios-integration.component';
import { O365IntegrationComponent } from '../components/widgets/1.0/o365-integration/o365-integration.component';
import { OptimizerChecksComponent } from '../components/widgets/1.0/optimizer-checks/optimizer-checks.component';
import { OtrsIntegrationComponent } from '../components/widgets/1.0/otrs-integration/otrs-integration.component';
import { OtrsTicketListingComponent } from '../components/widgets/1.0/otrs-ticket-listing/otrs-ticket-listing.component';
import { OverviewComponent } from '../components/widgets/1.0/overview/overview.component';
import { PartnerVisibilityComponent } from '../components/widgets/1.0/partner-visibility/partner-visibility/partner-visibility.component';
import { ReportListingComponent } from '../components/widgets/1.0/report-listing/report-listing.component';
import { RiRecommendationComponent } from '../components/widgets/1.0/ri-recommendation/ri-recommendation.component';
import { RibbonComponent } from '../components/widgets/1.0/ribbon/ribbon.component';
import { SalesforceIntegrationComponent } from '../components/widgets/1.0/salesforce-integration/salesforce-integration.component';
import { ServiceCatalogComponent } from '../components/widgets/1.0/service-catalog/service-catalog.component';
import { ServicenowIntegrationComponent } from '../components/widgets/1.0/servicenow-integration/servicenow-integration.component';
import { SingleStackedBarComponent } from '../components/widgets/1.0/single-stacked-bar/single-stacked-bar.component';
import { SnsIntegrationComponent } from '../components/widgets/1.0/sns-integration/sns-integration.component';
import { SsoIntegrationComponent } from '../components/widgets/1.0/sso-integration/sso-integration.component';
import { TagConfigurationListingComponent } from '../components/widgets/1.0/tag-configuration-listing/tag-configuration-listing.component';
import { TerraformCreateWidgetComponent } from '../components/widgets/1.0/terraform-create-widget/terraform-create-widget.component';
import { TerraformRequestWidgetListingComponent } from '../components/widgets/1.0/terraform-request-widget-listing/terraform-request-widget-listing.component';
import { TopologyGraphComponent } from '../components/widgets/1.0/topology-graph/topology-graph.component';
import { UserProfileComponent } from '../components/widgets/1.0/user-profile/user-profile.component';
import { UsersListingComponent } from '../components/widgets/1.0/users-listing/users-listing.component';
import { UtilisationComponent } from '../components/widgets/1.0/utilisation/utilisation.component';
import { ViewsComponent } from '../components/widgets/1.0/views/views.component';
import { WidgetCatalogComponent } from '../components/widgets/1.0/widget-catalog/widget-catalog.component';
import { WidgetDocumentationListingComponent } from '../components/widgets/1.0/widget-documentation-listing/widget-documentation-listing.component';
import { ZendeskIntegrationComponent } from '../components/widgets/1.0/zendesk-integration/zendesk-integration.component';
import { ZohoIntegrationComponent } from '../components/widgets/1.0/zoho-integration/zoho-integration.component';
import { ZohoListingComponent } from '../components/widgets/1.0/zoho-listing/zoho-listing.component';
import { ExpandableTableV2Component } from '../components/widgets/2.0/expandable-table-v2/expandable-table-v2.component';
import { FreshserviceIntegrationV2Component } from '../components/widgets/2.0/freshservice-integration-v2/freshservice-integration-v2.component';
import { FreshserviceListingV2Component } from '../components/widgets/2.0/freshservice-listing-v2/freshservice-listing-v2.component';
import { OptimizerCheckV2Component } from '../components/widgets/2.0/optimizer-check-v2/optimizer-check-v2.component';
import { RegionalComponent } from '../components/widgets/2.0/regional/regional.component';
import { WhitelabelingComponent } from '../components/widgets/2.0/whitelabeling/whitelabeling.component';
import { OptimizerCheckV3Component } from '../components/widgets/3.0/optimizer-check-v3/optimizer-check-v3.component';
import { PortletType } from '../enums/PortletType';
import { PortletVersion } from '../enums/PortletVersion';
import { IPortletInfo } from '../interfaces/widget/IPortletInfo';
import { environment } from './../../../environments/environment';
import { AwsListingComponent } from './../components/widgets/1.0/aws-listing/aws-listing.component';
import { AzureCreateVmProvisioningComponent } from './../components/widgets/1.0/azure-create-vm-provisioning/azure-create-vm-provisioning.component';
import { AzureSponsorshipOnboardingListingComponent } from './../components/widgets/1.0/azure-sponsorship-onboarding-listing/azure-sponsorship-onboarding-listing.component';
import { CaTicketListingComponent } from './../components/widgets/1.0/ca-sdm-ticket-listing/ca-ticket-listing/ca-ticket-listing.component';
import { CreateVolumeProvisioningComponent } from './../components/widgets/1.0/create-volume-provisioning/create-volume-provisioning.component';
import { CspIntegrationComponent } from './../components/widgets/1.0/csp-integration/csp-integration.component';
import { CustomCostMonitoringWidgetListingComponent } from './../components/widgets/1.0/custom-cost-monitoring-widget-listing/custom-cost-monitoring-widget-listing.component';
import { CustomerListingV2Component } from './../components/widgets/1.0/customer-listing-v2/customer-listing-v2.component';
import { JiradeskIntegrationComponent } from './../components/widgets/1.0/jiradesk-integration/jiradesk-integration.component';
import { JiradeskTicketListingComponent } from './../components/widgets/1.0/jiradesk-ticket-listing/jiradesk-ticket-listing.component';
import { MyPriceListingComponent } from './../components/widgets/1.0/my-price-listing/my-price-listing/my-price-listing.component';
import { PlanIntegrationComponent } from './../components/widgets/1.0/plan-integration/plan-integration.component';
import { MfaComponent } from './../components/widgets/1.0/root-user-mfa/mfa/mfa.component';
import { SalesforceListingComponent } from './../components/widgets/1.0/salesforce-listing/salesforce-listing.component';
import { ServicenowIncidentsListingComponent } from './../components/widgets/1.0/servicenow-incidents-listing/servicenow-incidents-listing.component';
import { ServicenowRequestsListingComponent } from './../components/widgets/1.0/servicenow-requests-listing/servicenow-requests-listing.component';
import { SlackIntegrationComponent } from './../components/widgets/1.0/slack-integration/slack-integration.component';
import { SubDomainComponent } from './../components/widgets/1.0/sub-domain/sub-domain.component';
import { ZendeskListingComponent } from './../components/widgets/1.0/zendesk-listing/zendesk-listing.component';
import { TagConfigurationListingV2Component } from './../components/widgets/2.0/tag-configuration-listing-v2/tag-configuration-listing-v2.component';
import { AllocationChecksV3Component } from './../components/widgets/3.0/allocation-checks-v3/allocation-checks-v3.component';
import { EmailTemplatesComponent } from '../components/widgets/1.0/email-templates/email-templates.component';

export class WidgetTypeMap {
    static getComponent(portletInfo: IPortletInfo): Function {
        switch (portletInfo.version) {
            case PortletVersion._1:
                switch (portletInfo.type) {
                    case PortletType.ALLOCATIONCHECKS:
                        return AllocationChecksComponent;
                    case PortletType.EXPANDABLETABLE:
                        return ExpandableTableComponent;
                    case PortletType.AUTORECOMMENDATION:
                        return AutoRecommendationComponent;
                    case PortletType.RIRECOMMENDATION:
                        return RiRecommendationComponent;
                    case PortletType.AZURE_RIRECOMMENDATION:
                        return RiRecommendationComponent;
                    case PortletType.OPTIMIZERCHECKS:
                        return OptimizerChecksComponent;
                    case PortletType.AGGREGATE:
                        return AggregateComponent;
                    case PortletType.RIBBON:
                        return RibbonComponent;
                    case PortletType.OVERVIEW:
                        return OverviewComponent;
                    case PortletType.BARGRAPH:
                        return BargraphComponent;
                    case PortletType.VIEWS:
                        return ViewsComponent;
                    case PortletType.REPORT_LISTING:
                        return ReportListingComponent;
                    case PortletType.MANAGED_REPORT_LISTING:
                        return ManagedReportListingComponent;
                    case PortletType.TAGCONFIGURATION:
                        return TagConfigurationListingComponent;
                    case PortletType.GRAPH:
                        return TopologyGraphComponent;
                    case PortletType.CUSTOMER_PROFILE:
                        return CustomerProfileComponent;
                    case PortletType.USER_PROFILE:
                        return UserProfileComponent;
                    case PortletType.CUSTOMERS_LISTING:
                        return CustomerListingV2Component;
                    case PortletType.USERS_LISTING:
                        return UsersListingComponent;
                    case PortletType.DRILLABLE_TABLE:
                        return UtilisationComponent;
                    case PortletType.WHITELABEL_INTEGRATION:
                        return DomainConfigurationComponent; // No longer in use can be deleted
                    case PortletType.ALERT_LISTING:
                        return AlertListingComponent;
                    case PortletType.BUDGET_PIE:
                        return BudgetListingComponent;
                    case PortletType.BUDGET_TIMESERIES:
                        return BudgetListingComponent;
                    case PortletType.BUDGET_REGION:
                        return BudgetListingComponent;
                    case PortletType.GMAIL_INTEGRATION:
                        return GmailIntegrationComponent;
                    case PortletType.O365_INTEGRATION:
                        return O365IntegrationComponent;
                    case PortletType.SLACK_INTEGRATION:
                        return SlackIntegrationComponent;
                    case PortletType.FRESHDESK_INTEGRATION:
                        return FreshdeskIntegrationComponent;
                    case PortletType.FRESHSERVICE_INTEGRATION:
                        return FreshserviceIntegrationComponent;
                    case PortletType.ZENDESK_INTEGRATION:
                        return ZendeskIntegrationComponent;
                    case PortletType.SNS_INTEGRATION:
                        return SnsIntegrationComponent;
                    case PortletType.SERVICENOW_INTEGRATION:
                        return ServicenowIntegrationComponent;
                    case PortletType.FRESHDESK_TICKET_LISTING:
                        return FreshdeskListingComponent;
                    case PortletType.FRESHSERVICE_TICKET_LISTING:
                        return FreshserviceListingComponent;
                    case PortletType.ZENDESK_TICKET_LISTING:
                        return ZendeskListingComponent;
                    case PortletType.SERVICENOW_INCIDENTS:
                        return ServicenowIncidentsListingComponent;
                    case PortletType.SERVICENOW_REQUESTS:
                        return ServicenowRequestsListingComponent;
                    case PortletType.NAGIOS_INTEGRATION:
                        return NagiosIntegrationComponent;
                    case PortletType.CSP_INTEGRATION:
                        return CspIntegrationComponent;
                    case PortletType.EA_INTEGRATION:
                        return EaIntegrationComponent;
                    case PortletType.PLAN_INTEGRATION:
                        return PlanIntegrationComponent;
                    case PortletType.AWS_INTEGRATION:
                        return AwsIntegrationComponent;
                    case PortletType.AWS_ACCOUNT_LISTING:
                        return AwsListingComponent;
                    case PortletType.CSP_ACCOUNT_LISTING:
                        return CspListingComponent;
                    case PortletType.EA_ACCOUNT_LISTING:
                        return EaListingComponent;
                    case PortletType.CUSTOMER_SERVICE_LISTING:
                        return CustomWidgetListingComponent;
                    case PortletType.CUSTOMER_SERVICE:
                        return CustomRequestWidgetComponent;
                    case PortletType.AUTOMATION:
                        return AutomationListingComponent;
                    case PortletType.TAGGING_AUTOMATION:
                        return AdvancedAutomationComponent;
                    case PortletType.AZURE_CREATE_DISK_PROVISIONING:
                        return AzureCreateDiskProvisioningComponent;
                    case PortletType.ZOHO_INTEGRATION:
                        return ZohoIntegrationComponent;
                    case PortletType.AWS_EC2_VOLUME_PROVISIONING:
                        return CreateVolumeProvisioningComponent;
                    case PortletType.AZURE_PROVISIONING_RESOURCES:
                        return AzureResourceProvisioningComponent;
                    case PortletType.JIRADESK_INTEGRATION:
                        return JiradeskIntegrationComponent;
                    case PortletType.JIRADESK_TICKET_LISTING:
                        return JiradeskTicketListingComponent;
                    case PortletType.AWS_CREATE_INSTANCE_PROVISIONING:
                        return AwsCreateInstanceProvisioningComponent;
                    case PortletType.AZURE_CREATE_VM_PROVISIONING:
                        return AzureCreateVmProvisioningComponent;
                    case PortletType.FILTERS:
                        return FiltersConfigurationComponent;
                    case PortletType.GENERAL_CONFIGURATION:
                        return GeneralConfigurationComponent;
                    case PortletType.ZOHO_TICKET_LISTING:
                        return ZohoListingComponent;
                    case PortletType.OTRS_INTEGRATION:
                        return OtrsIntegrationComponent;
                    case PortletType.SERVICE_CATALOG:
                        return ServiceCatalogComponent;
                    case PortletType.AUTOTASK_INTEGRATION:
                        return AutotaskIntegrationComponent;
                    case PortletType.AUTOTASK_TICKET_LISTING:
                        return AutotaskTicketListingComponent;
                    case PortletType.SUB_DOMAIN:
                        return SubDomainComponent;
                    case PortletType.CASDM_INTEGRATION:
                        return CaIntegrationComponent;
                    case PortletType.OTRS_TICKET_LISTING:
                        return OtrsTicketListingComponent;
                    case PortletType.SAML_INTEGRATION:
                        return SsoIntegrationComponent;
                    case PortletType.CASDM_TICKET_LISTING:
                        return CaTicketListingComponent;
                    case PortletType.CUSTOM_COMPLIANCE:
                        return CustomComplianceListingComponent;
                    case PortletType.ROOT_USER_MFA:
                        return MfaComponent;
                    case PortletType.USER_MFA:
                        return MfaComponent;
                    case PortletType.LOGIN_METRICS:
                        return LoginMetricsComponent;
                    case PortletType.ADOPTION_METRICS:
                        return AdoptionMetricsComponent;
                    case PortletType.GCP_ACCOUNT_LISTING:
                        return GcpListingComponent;
                    case PortletType.GCP_INTEGRATION:
                        return GcpIntegrationComponent;
                    case PortletType.MY_PRICE_CONFIGURATION:
                        return MyPriceListingComponent;
                    case PortletType.CHARGE_TYPE:
                        return PartnerVisibilityComponent;
                    case PortletType.AZURE_ARM_JSON_TEMPLATE:
                        return ArmJsonTemplatesListingComponent;
                    case PortletType.WIDGET_CATALOG:
                        return WidgetCatalogComponent;
                    case PortletType.DEPLOY_WIDGET:
                        return CreateWidgetJsonComponent;
                    case PortletType.AUDIT:
                        return AssessmentListingComponent;
                    case PortletType.ASSESSMENT:
                        return IssueAssessmentListingComponent;
                    case PortletType.PAY_AS_YOU_GO_INTEGRATION:
                        return AzureSponsorshipIntegrationComponent;
                    case PortletType.PAY_AS_YOU_GO_LISTING:
                        return AzureSponsorshipOnboardingListingComponent;
                    case PortletType.SPONSORSHIP_INTEGRATION:
                        return AzureSponsorshipIntegrationComponent;
                    case PortletType.SPONSORSHIP_LISTING:
                        return AzureSponsorshipOnboardingListingComponent;
                    case PortletType.CUSTOM_COST_MONITORING_CREATION:
                        return CustomCostMonitoringWidgetListingComponent;
                    case PortletType.SINGLE_STACKED_BAR:
                        return SingleStackedBarComponent;
                    case PortletType.MCA_INTEGRATION:
                        return AzureMcaIntegrationComponent;
                    case PortletType.ADD_WIDGET_DOCUMENTATION:
                        return WidgetDocumentationListingComponent;
                    case PortletType.TERRAFORM_WIDGET_LISTING:
                        return TerraformCreateWidgetComponent;
                    case PortletType.TERRAFORM_RUNBOOK:
                        return TerraformRequestWidgetListingComponent;
                    case PortletType.SALESFORCE_INTEGRATION:
                        return SalesforceIntegrationComponent;
                    case PortletType.SALESFORCE_LISTING:
                        return SalesforceListingComponent;
                    case PortletType.USERS_LISTING_ASSESSMENT:
                        return AssessmentUserAccessComponent;
                    case PortletType.ASSESSMENT_ASSESSEE:
                        return AssessmentAssesseeComponent;
                    case PortletType.EMAIL_TEMPLATES:
                        return EmailTemplatesComponent;
                }
                break;
            case PortletVersion._2:
                switch (portletInfo.type) {
                    case PortletType.PIE:
                        return PieComponent;
                    case PortletType.REGIONAL:
                        return RegionalComponent;
                    case PortletType.OPTIMIZERCHECKS:
                        return OptimizerCheckV2Component;
                    case PortletType.ALLOCATIONCHECKS:
                        return AllocationChecksV2Component;
                    case PortletType.TAGCONFIGURATION:
                        return TagConfigurationListingV2Component;
                    case PortletType.AZURE_RIRECOMMENDATION:
                        return RiRecommendationComponent;
                    case PortletType.FRESHSERVICE_INTEGRATION:
                        return FreshserviceIntegrationV2Component;
                    case PortletType.FRESHSERVICE_TICKET_LISTING:
                        return FreshserviceListingV2Component;
                    case PortletType.EXPANDABLETABLE:
                        return ExpandableTableV2Component;
                    case PortletType.ACCESS_CONTROL_LIST:
                        return AclListingComponent;
                    case PortletType.WHITELABEL_INTEGRATION:
                        return WhitelabelingComponent;
                    case PortletType.MOCK_WIDGET:
                        if (environment.mock) {
                            return environment.mockComponent;
                        } else {
                            return null;
                        }
                }
                break;
            case PortletVersion._3:
                switch (portletInfo.type) {
                    case PortletType.ALLOCATIONCHECKS:
                        return AllocationChecksV3Component;
                    case PortletType.OPTIMIZERCHECKS:
                        return OptimizerCheckV3Component;
                }
                break;
            default:
                return null;
        }

        return null;
    }
}
